import axios from "axios";
import { API_CONFIG } from "../../config/main";
import ResetToken from "../../utils/ResetToken";

const BASE_URL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}`;

export function fetchVehicles(params) {
  const apiURL = `${BASE_URL}${API_CONFIG.END_POINTS.VEHICLES.GET_ALL()}`;
  return axios
    .get(apiURL, {
      params: params,
    })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Vehicles data fetched successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      if (err.response.status === 401) {
        ResetToken();
      }
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function fetchVehicle(id, params = {}) {
  const apiURL = `${BASE_URL}${API_CONFIG.END_POINTS.VEHICLES.GET_ONE(id)}`;
  return axios
    .get(apiURL, {
      params: params,
    })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Vehicle data fetched successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      if (err.response.status === 401) {
        ResetToken();
      }
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}
