import React, { Component } from "react";
import './style.scss';

export default () => {
	return(
		<div className="grid-container">
			<div className="grid-x grid-padding-x align-center">
				<div className="cell large-8">

					<div id="notfound">
						<div className="notfound">
							<div className="notfound-404">
								<h1>Oops!</h1>
								<h2>404 - The Page can't be found</h2>
							</div>
							<a href="/">Go TO Homepage</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
