import axios from "axios";
import { API_CONFIG } from "../../config/main";
import ResetToken from "../../utils/ResetToken";
import { SET_CONTACT_FILTER } from "../Types/Types";

const BASE_URL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}`;
const DRIVESHOP_BASE = `${API_CONFIG.DRIVESHOP_URL}${API_CONFIG.BASE_PATH}`;

export function fetchContacts(params) {
  const apiURL = `${BASE_URL}${API_CONFIG.END_POINTS.CONTACTS.GET_ALL()}`;
  return axios
    .get(apiURL, {
      params: params,
    })
    .then((res) => {
      const success = [200, 204].indexOf(res.status) > -1;
      return {
        success: success,
        message: "Contacts data fetched successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      if (err.response.status === 401) {
        ResetToken();
      }

      return {
        success: false,
        message: err.response.data.errors
          ? err.response.data.errors.map((a) => a.detail).join(",")
          : "Something went wrong.",
      };
    });
}

export function fetchContact(id, params = {}) {
  const apiURL = `${BASE_URL}${API_CONFIG.END_POINTS.CONTACTS.GET_ONE(id)}`;
  return axios
    .get(apiURL, {
      params: params,
    })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "contact data fetched successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      if (err.response.status === 401) {
        ResetToken();
      }
      return {
        success: false,
        message: err.response.data.errors
          ? err.response.data.errors.map((a) => a.detail).join(",")
          : "Something went wrong.",
      };
    });
}

export function setContactFilters(filters = {}) {
  return {
    type: SET_CONTACT_FILTER,
    filters,
  };
}

export const getMugshotImage = async (id) => {
  const apiURL = `${DRIVESHOP_BASE}${API_CONFIG.END_POINTS.CONTACTS.GET_MUGSHOT(
    id
  )}`;
  try {
    const response = await axios.get(apiURL, {
      headers: {
        Authorization: `Token ${localStorage.fmsToken}`,
      },
    });
    const success = response.status === 200;
    return {
      success: success,
      message: "contact image fetched successfully.",
      data: response.data.mugshot,
    };
  } catch (err) {
    return {
      success: false,
      message: err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.",
    };
  }
};
