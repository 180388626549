import React, { useState } from "react";
import { fetchOffices } from "../../Redux/Actions/Offices";
import { fetchContacts, setContactFilters } from "../../Redux/Actions/Contacts";
import Loader from "../../Components/Loader/Loader";
import { useQuery, useIsFetching } from "react-query";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import fuzzysort from "fuzzysort";

const ContactList = React.memo(({ contacts }) => {
  const history = useHistory();

  const formatNum = (value = "") => {
    return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
  };

  return contacts.length > 0 ? (
    contacts.map((contact) => {
      return (
        <li key={Math.random()} className="contact-page__list">
          <div className="contact-details-page">
            <div className="container">
              <p
                onClick={(e) => history.push(`contact/${contact.id}`)}
                className="email name mb-2"
              >
                {`${contact.first_name} ${contact.last_name}`}
              </p>
              <p className="email mb-2">
                <a href={`mailto:${contact.email}`}>{contact.email}</a>
              </p>
              {contact.phone_and_type.map((record, index) => {
                return Object.keys(record).map((key) => {
                  return (
                    <div key={index}>
                      <span>
                        {key}:{" "}
                        <a href={`tel:${record[key]}`}>
                          {formatNum(record[key])}
                        </a>
                      </span>
                    </div>
                  );
                });
              })}
            </div>
          </div>
        </li>
      );
    })
  ) : (
    <li>No records found.</li>
  );
});

const Contact = ({ ...props }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.filters);
  const { name, selectedOffice } = filters;
  const [offices, setOffices] = useState([]);
  const [contacts, setContacts] = useState([]);
  const isLoading = useIsFetching();

  useQuery("offices", async () => await fetchOffices(), {
    onSuccess: ({ data: offices }) => setOffices(offices),
    initialData: [],
  });

  useQuery("fetchContacts", async () => fetchContacts(), {
    onSuccess: ({ data: _contacts }) => setContacts(_contacts),
    initialData: { data: [] },
  });

  const setName = (value) => {
    dispatch(setContactFilters({ ...filters, name: value }));
  };

  const selectOffice = (value) => {
    dispatch(setContactFilters({ ...filters, selectedOffice: value }));
  };

  const filteredContacts = () => {
    let filtered = contacts;
    const fuzzyParams = {
      keys: ["first_name", "last_name", "middle_name"],
      threshold: -50,
    };
    if (!name && !selectedOffice) {
      return contacts;
    }

    if (name && !selectedOffice) {
      filtered = fuzzysort.go(name, contacts, fuzzyParams);
      return filtered.map((result) => result.obj);
    }

    if (!name && selectedOffice) {
      filtered = contacts.filter(
        (contact) => contact.office_id === parseInt(selectedOffice)
      );
      return filtered;
    }

    if (name && selectedOffice) {
      filtered = contacts.filter(
        (contact) => contact.office_id === parseInt(selectedOffice)
      );

      filtered = fuzzysort.go(name, filtered, fuzzyParams);
      return filtered.map((result) => result.obj);
    }
  };

  return (
    <div className="activity-page__container">
      <ul>
        <li>
          <strong>Contacts</strong>
          <div className="contact-details-page">
            <form className="activity-page__form pb-2">
              <div className="row">
                <div className="col-8 d-flex pl-2">
                  <div className="col-3">
                    <label>Name:</label>
                  </div>
                  <div className="col-9 p-0">
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-4"></div>
              </div>

              <div className="row">
                <div className="col-8 d-flex pl-2">
                  <div className="col-3">
                    <label>Office:</label>
                  </div>

                  <div className="col-9 p-0">
                    <select
                      name="offices"
                      onChange={(e) => selectOffice(e.target.value)}
                      value={selectedOffice}
                      required={name ? false : true}
                    >
                      <option></option>
                      {offices.map((office) => {
                        return (
                          <option key={office.id} value={office.id}>
                            {office.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-4 p-0"></div>
              </div>
            </form>
          </div>
        </li>
        {isLoading ? <Loader /> : null}
        <ContactList contacts={filteredContacts()} />
      </ul>
    </div>
  );
};
export default Contact;
