import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import thunk from 'redux-thunk';
import rootReducer from './Redux/Reducers/rootReducer';
import setAuthorizationToken from './utils/SetAuthorizationToken';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { logout } from './Redux/Actions/Auth';
import { BrowserRouter, Route } from 'react-router-dom';
import './index.css';
import 'jquery/src/jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import 'react-notifications/lib/notifications.css';
import './index.css';
import './assets/css/common.css';
import './assets/css/responsive.css';

// Create Redux Store
const store = createStore(

  rootReducer,
  compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )

);

// Check if token is valid
if(localStorage.fmsToken && localStorage.fmsTokenExpiry) {

  var now = new Date()

  // check date of token
  if(now > new Date(localStorage.fmsTokenExpiry)) {
    store.dispatch(logout());

    // redirect to login?
  } else {
    setAuthorizationToken(localStorage.fmsToken);
  }

} else {
  store.dispatch(logout());
}

ReactDOM.render(
  <React.StrictMode>
	<BrowserRouter>
		<Provider store={store}>
			<Route component={App} />
		</Provider>
	</BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
