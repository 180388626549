import { combineReducers } from 'redux';

import auth from './Auth';
import loader from './Loader';
import {filters} from './Contact';

export default combineReducers({
  auth,
  loader,
  filters
});
