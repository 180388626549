import React, { useState, useRef } from "react";
import { login } from "../../Redux/Actions/Auth";
import { setLoader } from "../../Redux/Actions/Loader";
import { handleResponse } from "../../utils/Notification";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./login.css";

const Login = (props) => {
  const [loginData, setLoginData] = useState({});
  const loading = useSelector((state) => state.loader.loading);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = (e) => {
    setLoginData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    await dispatch(setLoader(true));
    const response = await dispatch(
      login({ user: loginData.user, password: loginData.password })
    );
    await dispatch(setLoader(false));
    handleResponse(response);
    if (response.success) {
      history.push("/");
    }
  };

  return (
    <div className="login-page-form__container">
      <form onSubmit={handleLogin}>
        <img src="logo.png" alt="logo" />
        <div className="email-login">
          <label htmlFor="user">Username</label>
          <input type="text" name="user" onChange={handleChange} required />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            onChange={handleChange}
            required
          />
        </div>
        <button className="cta-btn" disabled={loading}>
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
