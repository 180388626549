import React, { Component } from "react";
import LoginPage from "./Pages/LoginPage/Form";
import NoRouteFound from "./Pages/PageNotFound/PageNotFound";
import Header from "./Components/Header/Header";
import Activity from "./Pages/Activity/Activity";
import Vehicles from "./Pages/Vehicles/Vehicles";
import VehicleDetails from "./Pages/Vehicle/Vehicle";
import Contacts from "./Pages/Contact/Contact";
import ContactDetails from "./Pages/Contact/Details";
import ActivityEdit from "./Pages/Activity/Edit/Form";
import { Switch, Route, Redirect } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <div className="app-frame">
        <Header />
        {this.props.children}
      </div>
    );
  }
}

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function PublicOnlyRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === false ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
}

const App = ({ location, history, ...props }) => {
  const authenticated = localStorage.fmsToken ? true : false;

  return (
    <QueryClientProvider client={queryClient}>
      <ScrollToTop location={location}>
        <NotificationContainer />
        <ToastContainer />
        <Switch>
          <PrivateRoute
            history={history}
            authed={authenticated}
            location={location}
            path="/"
            exact
            component={Activity}
          />
          <PublicOnlyRoute
            authed={authenticated}
            location={location}
            path="/login"
            component={LoginPage}
          />
          <PrivateRoute
            history={history}
            authed={authenticated}
            location={location}
            exact
            path="/activity/:id"
            component={ActivityEdit}
          />
          <PrivateRoute
            history={history}
            authed={authenticated}
            location={location}
            exact
            path="/contact/:id"
            component={ContactDetails}
          />
          <PrivateRoute
            history={history}
            authed={authenticated}
            location={location}
            path="/contacts"
            component={Contacts}
          />
          <PrivateRoute
            history={history}
            authed={authenticated}
            location={location}
            path="/vehicles"
            component={Vehicles}
          />
          <PrivateRoute
            history={history}
            authed={authenticated}
            location={location}
            path="/vehicle/:id"
            component={VehicleDetails}
          />
          <Route component={NoRouteFound} />
        </Switch>
      </ScrollToTop>
    </QueryClientProvider>
  );
};

export default App;
