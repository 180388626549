import "./Loader.css";

const Loader = () => {
  return (
    <>
      <div className="gooey">
        <div className="dots">
          <span className="dot"></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </>
  );
};

// const Loader = () => {
//   let loading = useSelector((state) => state.loader.loading);

//   return (
//     <>
//       {loading ? (
//         <div className="gooey">
//           <div className="dots">
//             <span className="dot"></span>
//             <span></span>
//             <span></span>
//             <span></span>
//           </div>
//         </div>
//       ) : null}
//     </>
//   );
// };

export default Loader;
