import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { logout } from '../../Redux/Actions/Auth';
import { useHistory } from "react-router-dom";
import './header-page.css';

export default () => {
	const [visible, setVisible] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	const handleShow = () => {
		setVisible(visible ? false : true)
	}

	const handleLogout = async (e) => {
		e.preventDefault();
		await dispatch(logout())
		setVisible(false);
		history.push("/");
	}

	return(
		<div className="topnav">
			<div className="active" />
			<div id="myLinks" style={{display: visible ? 'block' : 'none'}}>
				<Link to="/" onClick={handleShow}>Activity</Link>
				<Link to="/vehicles" onClick={handleShow}>Vehicles</Link>
				<Link to="/contacts" onClick={handleShow}>Contacts</Link>
				<Link to="#" onClick={(e) => handleLogout(e)}>Sign Out</Link>
			</div>

			<a href="#" onClick={handleShow} className="icon">
				<i className="fa fa-bars"></i>
			</a>
		</div>
	)
}