import { SET_LOADER } from '../Types/Types';

const initialState = {
  loading: false,
}

export default(state = initialState, action = {}) => {
  switch(action.type) {
    case SET_LOADER:
      return {
        loading: action.value
      }

    default: return state;
  }
}