import React, { useState } from "react";
import Loader from "../../Components/Loader/Loader";
import { fetchOffices } from "../../Redux/Actions/Offices";
import { fetchVehicles } from "../../Redux/Actions/Vehicles";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useQuery, useIsFetching, useMutation } from "react-query";

const VehicleList = React.memo(({ vehicles }) => {
  if (vehicles.length <= 0) {
    return <li>No records found.</li>;
  }
  return (
    <>
      {vehicles.map((vehicle) => {
        return (
          <li key={Math.random()}>
            <div className="activity_page-details">
              <div className="activity__left-content" style={{ width: "100%" }}>
                <h6>
                  {vehicle.year} {vehicle.vehicle_make}{" "}
                  {vehicle.vehicle_model}
                </h6>
                <div className="activity__sub-details">
                  <Link to={`/vehicle/${vehicle.id}`}>
                    <span className="capitalize vehicle-vin">
                      VIN: {vehicle.vin.slice(-8)}
                    </span>
                  </Link>
                  <br />
                  <span>Plate: {vehicle.plate}</span>
                  <br />
                  <span>
                    {vehicle.exterior_color} | {vehicle.engine_type}
                  </span>
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </>
  );
});

export const Vehicles = () => {
  const [vehicles, setVehicles] = useState([]);
  const [offices, setOffices] = useState([]);
  const [selectedOffice, selectOffice] = useState("");
  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [selectedMake, setSelectedMake] = useState("");
  const [vin, setVin] = useState("");
  const [hasFetchedInitialVehicles, setHasFetchedInitialVehicles] =
    useState(false);
  const isLoading = useIsFetching();
  const [searchParams, setSearchParams] = useState({});

  useQuery("offices", async () => await fetchOffices(), {
    onSuccess: (officeResponse) => {
      const { data } = officeResponse;
      setOffices(data);
    },
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const { refetch: refetchVehicleData } = useQuery(
    ["vehicleData", searchParams],
    async ({ queryKey }) => {
      const [_, _searchParams] = queryKey;
      return await fetchVehicles(_searchParams);
    },
    {
      onSuccess: (vehicleResponse) => {
        const { data } = vehicleResponse;
        setVehicles(data);
        if (!hasFetchedInitialVehicles) {
          getAvailableVehicleMakes(data);
        }
        setHasFetchedInitialVehicles(true);
      },
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );

  const getAvailableVehicleMakes = (allVehicles) => {
    const allVehicleMakes = allVehicles.map((vehicle) => vehicle.vehicle_make);
    const availableVehicleMakes = Array.from(new Set(allVehicleMakes));
    setVehicleMakes(availableVehicleMakes);
  };

  const { mutate: handleSearch } = useMutation(
    "handleVehicleSearch",
    async (e) => {
      e.preventDefault();
      const params = {
        office_id: selectedOffice,
        vin: vin,
        make: selectedMake,
      };
      setSearchParams(params);
      refetchVehicleData();
    }
  );

  return (
    <div className="activity-page__container">
      <ul>
        <li>
          <strong>Vehicles</strong>
          <div className="activity_page-details">
            <form onSubmit={handleSearch} className="activity-page__form pb-2">
              <div className="row">
                <div className="col-8 d-flex pl-2">
                  <div className="col-3">
                    <label>VIN:</label>
                  </div>
                  <div className="col-9 p-0">
                    <input
                      type="text"
                      value={vin}
                      onChange={(e) => setVin(e.target.value)}
                      required={!(vin || selectedOffice || selectedMake)}
                    />
                  </div>
                </div>
                <div className="col-4"></div>
              </div>

              <div className="row">
                <div className="col-8 d-flex pl-2">
                  <div className="col-3">
                    <label>Office:</label>
                  </div>

                  <div className="col-9 p-0">
                    <select
                      name="offices"
                      onChange={(e) => selectOffice(e.target.value)}
                      value={selectedOffice}
                      required={!(vin || selectedOffice || selectedMake)}
                    >
                      <option></option>
                      {offices.map((office) => {
                        return (
                          <option key={Math.random()} value={office.id}>
                            {office.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-8 d-flex pl-2">
                  <div className="col-3">
                    <label>Make:</label>
                  </div>

                  <div className="col-9 p-0">
                    <select
                      name="vehicleMake"
                      onChange={(e) => setSelectedMake(e.target.value)}
                      value={selectedMake}
                      required={false}
                    >
                      <option></option>
                      {vehicleMakes.map((make) => {
                        return (
                          <option key={make} value={make}>
                            {make}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-4 p-0">
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary search-btn"
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>
        {isLoading ? <Loader /> : null}
        <VehicleList vehicles={vehicles} />
      </ul>
    </div>
  );
};

export default Vehicles;
