import axios from "axios";
import setAuthorizationToken from "../../utils/SetAuthorizationToken";
import { API_CONFIG } from "../../config/main";
import { SET_CURRENT_USER } from "../Types/Types";

const BASE_URL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}`;

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user: user,
  };
}

export function logout() {
  localStorage.clear();
  return (dispatch) => {
    setAuthorizationToken(false);
  };
}

export function setUserToken(token) {
  localStorage.removeItem("fmsToken");
  localStorage.removeItem("fmsTokenExpiry");
  localStorage.removeItem("fmsUser");

  localStorage.setItem("fmsToken", token.auth_token);
  localStorage.setItem("fmsTokenExpiry", token.expires_on);
  localStorage.setItem("fmsUser", JSON.stringify(token));
}

export function login(loginData) {
  const apiURL = `${BASE_URL}${API_CONFIG.END_POINTS.AUTH.SIGN_IN()}`;
  return (dispatch) =>
    axios
      .post(apiURL, loginData)
      .then((res) => {
        const success = res.status === 200;
        if (success) {
          const token = res.data;
          setUserToken(token);
          setAuthorizationToken(token, "");
        }
        return { success: success, message: "User logged-in successfully." };
      })
      .catch((err) => {
        return {
          success: false,
          message: err.response.data.errors
            ? err.response.data.errors.map((a) => a.detail).join(",")
            : "Something went wrong.",
        };
      });
}
