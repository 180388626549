import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./style.css";

const PercentageLoader = ({ percentage }) => {
  return (
    <>
      <div className="gooey">
        <div className="progressbar">
          <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
            styles={buildStyles({
              // Rotation of path and trail, in number of turns (0-1)
              rotation: 0.25,

              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: "butt",

              // Text size
              textSize: "12px",

              // How long animation takes to go from one percentage to another, in seconds
              pathTransitionDuration: 0.5,

              // Can specify path transition in more detail, or remove it entirely
              // pathTransition: 'none',

              // Colors
              pathColor: `#6c757d`,
              textColor: "#000",
              trailColor: "#f8f9fa",
              backgroundColor: "#3e98c7",
            })}
          />
        </div>
      </div>
    </>
  );
};

export default PercentageLoader;
