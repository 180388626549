export const API_CONFIG = {
  BASE_API_URL: process.env.REACT_APP_API_URL,
  DRIVESHOP_URL: process.env.REACT_APP_DRIVESHOP_URL,
  BASE_PATH: "/api",
  END_POINTS: {
    AUTH: {
      SIGN_IN: () => "/login",
    },
    ACTIVITY: {
      GET_ALL: () => "/my_activity",
      FETCH_DETAILS: (id) => `/my_activity/${id}`,
      UPDATE: (id) => `/my_activity/${id}`,
      RECEIPTS: {
        GET_ALL: () => "/v1/receipts",
        UPLOAD: () => "/v1/upload_receipts",
        DELETE_UPLOAD: (id) => `/v1/upload_receipts/${id}`, // Delete's processing images
        DELETE: (id) => `/v1/receipts/${id}`,
      },
      ATTACHMENT: {
        GET_ALL: () => "/v1/attachments",
        UPLOAD: () => "/v1/attachments",
        DELETE: (id) => `/v1/attachments/${id}`,
      },
      EXPENSE: {
        CREATE: (id) => `/my_activity/${id}/activity_costs`,
        FETCH_ONE: (id) => `/my_activity/${id}/activity_item_costs`,
        UPDATE: (id, costId) => `/my_activity/${id}/activity_costs/${costId}`,
        DELETE: (id, costId) => `/my_activity/${id}/activity_costs/${costId}`,
      },
    },
    FEE_TYPES: {
      GET_ALL: () => "/fee_types",
    },
    DRIVERS: {
      GET_ALL: (id) => `/my_activity/${id}/drivers`,
    },
    CONTACTS: {
      GET_ALL: () => "/contacts",
      GET_ONE: (id) => `/contacts/${id}`,
      GET_MUGSHOT: (id) => `/v1/people/${id}/mugshot`,
    },
    VEHICLES: {
      GET_ALL: () => "/vehicles",
      GET_ONE: (id) => `/vehicles/${id}`,
    },
    OFFICES: {
      GET_ALL: () => "/offices",
    },
    PAYMENT: {
      GET_ALL: () => "/payment_methods",
    },
    PREP_TYPES: {
      FETCH_ALL: () => "/extra_prep_types",
    },
  },
};
