import { SET_CONTACT_FILTER } from '../Types/Types';

const initialState = {
 	name: '',
	selectedOffice: ''
}

export const filters = (state = initialState, action = {}) => {
  switch(action.type) {
    case SET_CONTACT_FILTER:
      return action.filters

    default: return state;
  }
}