export const formatDate = (date, type = "yyyy/mm/dd") => {
  return type === "yyyy/mm/dd"
    ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    : `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
};

export const convertToMonthDayYear = (date) => {
  const dateObj = new Date(date);
  var month = dateObj.getUTCMonth() + 1; //months from 1-12
  var day = dateObj.getUTCDate();
  var year = dateObj.getUTCFullYear();

  return `${month}/${day}/${year}`;
};
