import React, { useState, useEffect } from "react";
import "./form.scss";

const ExpenseForm = ({
  activityData = {},
  data = {},
  feeTypes = [],
  paymentMethods = [],
  resetSelectedActivity = () => {},
  handleExpenseUpdate = () => {},
  ...props
}) => {
  const [expenseData, setExpenseData] = useState(data);

  useEffect(() => {
    const paymentMethod = paymentMethods.find(
      (a) => a.name === expenseData.payment_method
    );
    const expenseType = feeTypes.find((a) => a.name === expenseData.fee_type);
    setExpenseData({
      fee_type_id: expenseType.id,
      payment_method_id: paymentMethod.id,
      ...data,
    });
  }, [data]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleExpenseUpdate(expenseData);
    resetSelectedActivity();
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setExpenseData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="activity-page-edit__container">
      <form
        className="expense-page-edit__form"
        onSubmit={(e) => handleFormSubmit(e)}
      >
        <div className="container mt-2">
          <div className="row">
            <div className="col">
              <label htmlFor="expense_type">Type:</label>
            </div>
            <div className="col">
              <select
                name="fee_type_id"
                onChange={(e) => handleChange(e)}
                value={expenseData.fee_type_id}
                required
              >
                <option>Select item.</option>
                {feeTypes.map((prep) => {
                  return (
                    <option key={prep.id} value={prep.id}>
                      {prep.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label htmlFor="cost">Cost:</label>
            </div>
            <div className="col">
              <input
                type="number"
                name="cost"
                onChange={handleChange}
                value={expenseData.cost}
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label htmlFor="payment_method">Pmt Method:</label>
            </div>
            <div className="col">
              <select
                name="payment_method_id"
                onChange={(e) => handleChange(e)}
                value={expenseData.payment_method_id}
                required
              >
                <option>Select item.</option>
                {paymentMethods.map((mathod) => {
                  return (
                    <option key={mathod.id} value={mathod.id}>
                      {mathod.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <button type="submit" className="btn btn-success">
                Save
              </button>
              <button
                type="cancel"
                onClick={() => resetSelectedActivity()}
                className="ml-2 btn btn-secondary"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ExpenseForm;
