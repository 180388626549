import React from "react";
import "react-toastify/dist/ReactToastify.css";

const FixedScrollHeader = ({ editACtivity, handleSave }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "sticky",
        top: 10,
        zIndex: 10,
        margin: "10px 0px",
      }}
    >
      <button
        style={{ margin: "0 5px" }}
        type="button"
        onClick={(e) => handleSave(e)}
        className="btn btn-success"
      >
        {editACtivity.id ? "Back" : "Save"}
      </button>
      <button
        style={{ margin: "0 5px" }}
        type="button"
        onClick={(e) => handleSave(e, true)}
        className="btn btn-success"
      >
        Save & Stay
      </button>
    </div>
  );
};

export default FixedScrollHeader;
